<template>
  <router-view />
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import '@/plugins/styles'

import { languageCode2id, languageId2code } from './utilities/tools'
import useTextResource from './utilities/useTextResource'

export default {
  name: 'App',
  setup() {
    const store = useStore()
    store.dispatch('setting/setSetting')
    const sidebarType = computed(() => store.getters['setting/sidebar_type'])
    const resizePlugin = () => {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            store.dispatch('setting/sidebar_type', [...sidebarType.value, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            store.dispatch(
              'setting/sidebar_type',
              sidebarType.value.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }
    
    const { setTextResource } = useTextResource()
    
    onMounted(() => {
      window.addEventListener('resize', resizePlugin)
      setTimeout(() => {
        resizePlugin()
      }, 200)

      if (localStorage.ltsLicTextResources) {
        const savedLangId = languageCode2id(localStorage.ltsLicLanguage)
        const languageId = savedLangId || localStorage.ltsLicLanguageId || localStorage.ltsLicDefaultLanguageId
        setTextResource(languageId2code(languageId), JSON.parse(localStorage.ltsLicTextResources))
        
        localStorage.removeItem('ltsLicDefaultLanguageId')
        localStorage.removeItem('ltsLicTextResources')
      }else{
        setTextResource();
      }  
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizePlugin)
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
</style>
