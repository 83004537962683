import { defineStore } from 'pinia'
import { getAppInfo } from '../api/common'
import dayjs from 'dayjs'

export const useAppStore = defineStore('app', {
  state: () => ({
    appName: null,
    version: null,
    textUpdatedTime: null,
    defaultLanguageId: null,
    testSpeedSlowTimeInMilliSeconds: -1,
    testSpeedUrl: null,
    loadedLangMap: {
      en: false,
      id: false,
      th: false,
      vi: false,
    },
    languageIdCsv: null
  }),
  actions: {
    init(data) {
      const {
        appName,
        version,
        textUpdatedTime,
        defaultLanguageId,
        testSpeedSlowTimeInMilliSeconds,
        testSpeedUrl,
        languageIdCsv
      } = data
      this.$patch({
        appName,
        version,
        textUpdatedTime,
        defaultLanguageId,
        testSpeedSlowTimeInMilliSeconds,
        testSpeedUrl,
        languageIdCsv
      })
      this.markLangLoaded()
    },
    async fetch(callback) {
      const { returnCode, returnObject, returnCodeDetail } = await getAppInfo()
      if (returnCode === process.env.VUE_APP_API_RESULT_SUCCEEDED) {
        const { textUpdatedTime } = returnObject
        if (dayjs(textUpdatedTime).isAfter(this.textUpdatedTime)) {
          this.textUpdatedTime = textUpdatedTime
          typeof callback === 'function' && callback()
        }
        const { languageIdCsv } = returnObject
        this.$patch({ languageIdCsv })
      }
    },
    markLangLoaded() {
      this.loadedLangMap[localStorage.ltsLicLanguage] = true
    }
  },
  persist: {
    paths: ['languageIdCsv']
  }
})
