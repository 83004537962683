export const privileges = [
  { code: "NOT_APPLICABLE", value: -1 },
  { code: "SYS_LOGIN", value: 0 },
  { code: "NOT_DEFINED_YET", value: 1 },

  // ----------------------------------------------------------------------------------------------------

  { code: "LICENSEE_LIST", value: 101 },
  { code: "LICENSEE_ADD", value: 102 },
  { code: "LICENSEE_EDIT", value: 103 },
  { code: "LICENSEE_LOG_LIST", value: 104 },
  { code: "LICENSEE_DIRECT_MEMBER_SETTINGS_EDIT", value: 105 },
  { code: "LICENSEE_DIRECT_MEMBER_SETTINGS_LIST", value: 106 },
  { code: "LICENSEE_UPDATE_BALANCE", value: 196 },

  // ----------------------------------------------------------------------------------------------------

  { code: "LICENSEE_USER_LIST", value: 111 },
  { code: "LICENSEE_USER_ADD", value: 112 },
  { code: "LICENSEE_USER_EDIT", value: 113 },
  { code: "LICENSEE_USER_LOG_LIST", value: 114 },

  // ----------------------------------------------------------------------------------------------------

  { code: "LICENSEE_USER_GROUP_LIST", value: 121 },
  { code: "LICENSEE_USER_GROUP_ADD", value: 122 },
  { code: "LICENSEE_USER_GROUP_EDIT", value: 123 },
  { code: "LICENSEE_USER_GROUP_DELETE", value: 124 },

  // ----------------------------------------------------------------------------------------------------

  { code: "MEMBER_LIST", value: 131 },
  { code: "MEMBER_ADD", value: 132 },
  { code: "MEMBER_EDIT", value: 133 },
  { code: "MEMBER_LOG_LIST", value: 134 },
  { code: "MEMBER_UPDATE_BALANCE", value: 135 },

  // ----------------------------------------------------------------------------------------------------

  { code: "BET_LIST", value: 141 },

  // ----------------------------------------------------------------------------------------------------

  { code: "SETTLEMENT_REPORT", value: 151 },
  { code: "LICENSEE_MULTIPLE_TRANSFER_REPORT", value: 152 },
  { code: "DIRECT_MEMBER_MULTIPLE_TRANSFER_REPORT", value: 153 },
  { code: "LICENSEE_TRANSFER_YESTERDAY_AMOUNT_OWED_TO_UPLINE", value: 154 },
  { code: "MEMBER_TRANSFER_YESTERDAY_CREDIT_BALANCE", value: 155 },

  // ----------------------------------------------------------------------------------------------------

  { code: "EVENT_RESULT_LIST", value: 161 },

  // ----------------------------------------------------------------------------------------------------

  { code: "MEMBER_TRANSACTION_LIST", value: 171 },

  // ----------------------------------------------------------------------------------------------------

  { code: "BET_SUMMARY_REPORT", value: 181 },

  // ----------------------------------------------------------------------------------------------------

  { code: "INTEGRATION_INFO_LIST", value: 191 },
  { code: "INTEGRATION_INFO_EDIT", value: 193 },

  // ----------------------------------------------------------------------------------------------------

  { code: "QUERY_DASHBOARD_STAT", value: 201 },

  { code: "COMPANY_TICKET_REPORT", value: 211 },
  { code: "COMPANY_SHARES_REPORT", value: 212 },
  { code: "COMPANY_USDT_SETTLEMENT_REPORT", value: 213 },
];

export function convertPrivCode2Id(privilegeString) {
  const privilegeId = privileges.filter(
    (privilege) => privilege.code === privilegeString
  )[0].value;
  return privilegeId;
}

export function getLoginedUserPrivilegeString() {
  return localStorage.ltsLicLoginedUserPrivilegeString;
}

export function checkLoginedUserPrivilege(privilegeString) {
  const privilegeId = convertPrivCode2Id(privilegeString);
  return hasPrivilege(getLoginedUserPrivilegeString(), privilegeId);
}

export function hasPrivilege(privilegeString, privilegeId) {
  let result = false;

  if (privilegeId < 1 && privilegeId >= privilegeString.length) {
    result = false;
  } else {
    let character = parseInt(
      privilegeString.substring(privilegeId, privilegeId + 1)
    );
    result = character === 1 ? true : false;

    return result;
  }
}
