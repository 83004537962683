import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueLazyload from "vue-lazyload";

import { getAppInfo } from "./api/common";
import { useAppStore } from "./store/app";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faXmark,
  faPlus,
  faPenToSquare,
  faTrash,
  faKey,
  faHistory,
  faGamepad,
  faPlug,
  faCogs,
  faAward,
  faArrowLeft,
  faPowerOff,
  faCog,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
library.add(
  faCheck,
  faXmark,
  faPlus,
  faPenToSquare,
  faTrash,
  faKey,
  faHistory,
  faGamepad,
  faPlug,
  faCogs,
  faAward,
  faArrowLeft,
  faPowerOff,
  faCog,
  faRepeat,
  faQuestionCircle
);

// Library Components
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";
import BootstrapVue3 from "bootstrap-vue-3";
import CounterUp from "vue3-autocounter";
import VueDayjs from "vue3-dayjs-plugin";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// Custom Components & Directives
import globalComponent from "./plugins/global-components";
import globalDirective from "./plugins/global-directive";
import globalMixin from "./plugins/global-mixin";
require("waypoints/lib/noframework.waypoints.min");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(VueLazyload);

// Library Components
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.use(BootstrapVue3);
app.use(VueDayjs);
app.use(ElementPlus);

app.component("counter-up", CounterUp);
app.component("font-awesome-icon", FontAwesomeIcon);

// Custom Components & Directives
app.use(globalComponent);
app.use(globalDirective);
app.mixin(globalMixin);

const { returnCode, returnObject, returnCodeDetail } = await getAppInfo(0);

const { version } = returnObject;
const localVersion = localStorage.lts_licensee_version;
if (localVersion) {
  if (localVersion !== version) {
    localStorage.lts_licensee_version = version;
    location.reload();
  }
} else {
  localStorage.lts_licensee_version = version;
  location.reload();
}

if (returnCode === process.env.VUE_APP_API_RESULT_SUCCEEDED) {
  if (returnObject.textResources) {
    localStorage.ltsLicDefaultLanguageId = returnObject.defaultLanguageId;
    localStorage.ltsLicTextResources = JSON.stringify(
      returnObject.textResources
    );
  }

  const store = useAppStore();
  store.init(returnObject);

  app.mount("#app");
} else {
  app.mount("#app");
}
//export default app
