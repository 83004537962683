export const locale = localStorage.ltsLicLanguage

export const languages = [
  { id: 1, value: 'en', label: 'LANGUAGE_1' },
  { id: 2, value: 'id', label: 'LANGUAGE_2' },
  { id: 3, value: 'th', label: 'LANGUAGE_3' },
  { id: 4, value: 'vi', label: 'LANGUAGE_4' }
]

export function languageCode2id(code) {
  const find = languages.find((item) => item.value === code) || {}
  return find.id || ''
}

export function languageId2code(id) {
  const find = languages.find((item) => item.id === +id) || {}
  return find.value || locale
}

export function isLanguageCode(code) {
  const find = languages.find((item) => item.value === code) || {}
  return !!find.length
}

export function getLanguage() {
  return languages.find((item) => item.value === locale)?.label
}

export function hasToken() {
  return !!localStorage.ltsLicToken
}
