import { computed } from 'vue'
import { getAppInfo } from '../api/common'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '../store/app'

import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/id'
import 'dayjs/locale/th'
import 'dayjs/locale/vi'
import en from 'element-plus/es/locale/lang/en'
import id from 'element-plus/es/locale/lang/id'
import th from 'element-plus/es/locale/lang/th'
import vi from 'element-plus/es/locale/lang/vi'

export default function useTextResource() {
  const { t, setLocaleMessage, locale, messages } = useI18n()
  const store = useAppStore()
  const elementPlusLocaleMap = { en, id, th, vi }
  const elementPlusLocale = computed(() => elementPlusLocaleMap[locale.value])

  async function setTextResource(code, textResources) {
    const realCode = code || locale.value
    localStorage.ltsLicLanguage = realCode;
    dayjs.locale(realCode)
    code && (locale.value = realCode)
    if (textResources) {
      store.markLangLoaded()
      setLocaleMessage(realCode, textResources)
    } else if (!store.loadedLangMap[realCode]) {
      const { returnCode, returnObject, returnCodeDetail } = await getAppInfo(0)
      store.markLangLoaded()
      setLocaleMessage(realCode, Object.assign(messages.value[realCode], returnObject?.textResources))
    }
    setMetaContent()
  }

  function refreshTextResource() {
    getAppInfo(0).then(({ returnCode, returnObject, returnCodeDetail }) => {
      locale.value = localStorage.ltsLicLanguage;
      setTextResource(locale.value, returnObject?.textResources);
    })
  }

  function setMetaContent() {
    document.title = t('APP_INFO_LIC_SCREEN_HTML_TITLE')
    const $keyword = document.querySelector('meta[name="keywords"]')
    const $description = document.querySelector('meta[name="description"]')
    $keyword && $keyword.setAttribute('content', t('APP_INFO_LIC_SCREEN_HTML_META_KEYWORDS'))
    $description && $description.setAttribute('content', t('APP_INFO_LIC_SCREEN_HTML_META_DESCRIPTION'))
  }

  return { t, locale, elementPlusLocale, setTextResource, refreshTextResource }
}
