import axios from "axios";
import { ElMessage } from "element-plus";
import { languageCode2id } from "./tools";

import { refreshToken } from "@/api/auth";

axios.defaults.withCredentials = true;
const service = axios.create({
  timeout: 30 * 60 * 1000,
  baseURL: "/lts.licensee",
});

service.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${localStorage.ltsLicToken || ""}`;
  config.headers["language"] = languageCode2id(localStorage.ltsLicLanguage);
  config.headers["isRefreshToken"] = true;
  return config;
});

service.interceptors.response.use(
  (response) => {
    let rs = response.data;
    let returnCode = rs.returnCode;
    let returnObject = rs.returnObject;
    let returnCodeDetail = rs.returnCodeDetail || rs.returnCodeDetail;

    if (
      returnCode === process.env.VUE_APP_API_RESULT_SUCCEEDED ||
      rs.type === "Buffer"
    ) {
      rs.returnCode = returnCode;
      return rs;
    } else {
      showELMessages(returnCodeDetail);
      return { returnCode, returnObject, returnCodeDetail };
    }
  },
  (err) => {
    let returnCode = err.response.status;
    let returnObject = err.response.status;
    let returnCodeDetail = err.response.statusText;

    if (err.response.status == "401") {
      if (localStorage.ltsLicToken) {
        refreshToken({}).then(({ returnCode, returnObject }) => {
          if (
            returnCode === process.env.VUE_APP_API_RESULT_SUCCEEDED &&
            returnObject !== null
          ) {
            localStorage.ltsLicToken = returnObject;
          }
        });
      }
    }
    return { returnCode, returnObject, returnCodeDetail };
  }
);

function showELMessages(msg) {
  if (msg !== null) {
    if (typeof msg === "string") {
      if (msg.startsWith("[") && msg.endsWith("]")) {
        msg = [msg.substring(1, msg.length - 1)];
      } else {
        msg = [msg];
      }
    }

    msg.forEach((item) => {
      ElMessage({ type: "error", message: item, duration: 5 * 1000 });
    });
  }
}
export default service;
