import http from '@/utilities/http'

const prefix = '/Common/'

export async function getAppInfo(isAutoRefresh = 1) {
  const data = { isAutoRefresh : isAutoRefresh }
  return await http.post(prefix + 'AppInfo', data);
}

export async function getLoginedUserInfo() {
  return await http.post(prefix + 'GetLoginedUserInfo');
}

export async function getGameCodeList(data) {
  return await http.post(prefix + 'ListGameCode', data);
}

export async function getLicenseeStatusIdList(data) {
  return await http.post(prefix + 'ListLicenseeStatusId', data);
}

export async function getCurrencyCodeList(data) {
  return await http.post(prefix + 'ListCurrencyCode', data);
}

export async function getMemberPortalSkinList(data) {
  return await http.post(prefix + 'ListMemberPortalSkin', data);
}

export async function getMemberStatusIdList(data) {
  return await http.post(prefix + 'ListMemberStatusId', data);
}

export async function getPersonalNoticeList(data) {
  return await http.post(prefix + 'ListPersonalNotice', data);
}

