import http from '@/utilities/http'
import httpForRefreshToken from '@/utilities/httpForRefreshToken'

const prefix = '/Auth/'
export async function login(data) {
  return await http.post(prefix + 'DoLogin', data);
}

export async function logout() {
  return await http.post(prefix + 'DoLogout')
}

export async function refreshToken(data) {
  return await httpForRefreshToken.post(prefix + 'RefreshToken', data);
}

export async function keepAlive(data) {
  return await http.post(prefix + 'KeepAlive', data);
}
